import { makeStyles } from "@mui/styles"
import { colors } from "../../utils"

const useStyles = makeStyles(() => ({
	success: {
		"&.MuiFormControl-root": {
			"& .MuiInputLabel-root": {
				color: colors.green,
			},
			"& .MuiOutlinedInput-root": {
				"& .MuiOutlinedInput-notchedOutline": {
					borderColor: colors.green,
				},
			},
		},
	},
}))

export default useStyles
