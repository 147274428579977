export const isEmpty = (data) => {
	if (!data) {
		return true
	} else return false
}

export const isEmail = (data) => {
	const pattern = new RegExp(
		// eslint-disable-next-line no-useless-escape
		"^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
	)

	if (pattern.test(data?.toLowerCase())) {
		return false
	} else {
		return true
	}
}
