import { GET, POST } from "../utils/crud"

const gender = async () => {
  const response = await GET("parameters/gender")
  if (response.error) return { error: response.error }
  return response.data
}

const documentType = async () => {
  const response = await GET("parameters/document_type")
  if (response.error) return { error: response.error }
  return response.data
}

const sendData = async (body) => {
  const res = await POST("login", body)
  return res
}

const newUser = async (body) => {
  const res = await POST("register", body)
  return res
}

const recovery = async (body) => {
  const res = await POST("recover", body)
  return res
}

const change_password = async (body) => {
  const res = await POST("change-password", body)
  return res
}

const validateUser = async (token) => {
  const response = await POST("validate/session", {}, token)
  return response
}

const form = {
  gender,
  documentType,
  sendData,
  newUser,
  recovery,
  change_password,
  validateUser,
}

export default form
