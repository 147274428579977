import logoSanDiego from "../assets/images/LogoSanDiego-01.png"
import newLogo from "../assets/images/newLogo.png"
import img1 from "../assets/images/1.png"
import img2 from "../assets/images/2.png"
import img3 from "../assets/images/3.png"
import img4 from "../assets/images/4.png"
import img5 from "../assets/images/5.png"
import img6 from "../assets/images/6.png"

const images = {
  logoSanDiego,
  newLogo,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
}

export default images
