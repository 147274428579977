import useStyles from "./style"
import { isEmail, isEmpty } from "../../validators/validator"
import { Box, Typography } from "@mui/material"
import { lists } from "../../utils"

const ErrorMsg = ({ data, type }) => {
  const classes = useStyles()
  return type === 1 ? (
    <Box className={classes.errorContainer}>
      {data === undefined
        ? false
        : isEmpty(data) && (
            <Typography variant="caption" color="error">
              {lists.errors.required}
            </Typography>
          )}
    </Box>
  ) : type === 2 ? (
    <Box className={classes.errorContainer}>
      {data === undefined
        ? false
        : isEmpty(data) && (
            <Typography variant="caption" color="error">
              {lists.errors.required}
            </Typography>
          )}
      {isEmail(data) && isEmpty(!data) && (
        <Typography variant="caption" color="error">
          {lists.errors.invalidEmail}
        </Typography>
      )}
    </Box>
  ) : type === 3 ? (
    <Box className={classes.errorContainer}>
      {data
        ? (data.length < 10 || data.length > 13) && (
            <Typography variant="caption" color="error">
              {lists.errors.invalidPhone}
            </Typography>
          )
        : ""}
    </Box>
  ) : type === 4 ? (
    <Box className={classes.errorContainerDescription}>
      {data === undefined
        ? false
        : isEmpty(data) && (
            <Typography variant="caption" color="error">
              {lists.errors.required}
            </Typography>
          )}
    </Box>
  ) : (
    <Box className={classes.errorContainer}>
      {data === undefined
        ? false
        : (isEmpty(data) || data?.length < 8) && (
            <Typography variant="caption" color="error">
              {lists.errors.required}
            </Typography>
          )}
    </Box>
  )
}

export default ErrorMsg
