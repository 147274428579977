import { ThemeProvider } from "@mui/material/styles"
import { createTheme } from "@mui/material"
import LayoutRouter from "./router/layoutRouter"
import { colors } from "./utils"
import { esES } from "@mui/material/locale"
import { BrowserRouter } from "react-router-dom"
import "./css/App.css"

function App() {
	const theme = createTheme(
		{
			palette: {
				success: {
					main: colors.green,
				},
			},
		},
		esES
	)
	return (
		<BrowserRouter basename="/auth">
			<ThemeProvider theme={theme}>
				<LayoutRouter />
			</ThemeProvider>
		</BrowserRouter>
	)
}

export default App
