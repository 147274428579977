import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
  loadingContainer: {
    minHeight: "15vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainerButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // position: "absolute",
    // bottom: "23px",
    // left: "148px",
  },
}))

export default useStyles
