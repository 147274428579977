const colors = {
	primary: "#0076AF",
	primaryLighten: "#158BC8",
	primaryDarken: "#167DB7",
	deepBlue: "#071729",
	deepOceanBlue: "#194065",
	oceanBlue: "#1C5386",
	lightBlue: "#34A8E1",
	danger: "#DF3F3F",
	deepDanger: "#d32f2f",
	white: "#FFFFFF",
	green: "#179D0E",
	disable: "#8D8D8D",
	greyStrong: "#707070",
	greyFull: "#898989",
	greyLighten: "#CCCCCC",
	deepGrey: "#424242",
	deepGreen: "#297724",
	black: "#000000",
	borderGrey: "#c4c4c4",
	lightGrey: "#B0C2F4",
	passwordMsg: "#0076AF24",
}

export default colors
