import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { withStyles } from '@mui/styles'
import useStyles from './style'
import { colors } from '../../utils'

const ColorCircularProgress = withStyles({
  root: {
    color: colors.primary,
  },
})(CircularProgress)

export default function Loader({ size, buttonForm }) {
  const classes = useStyles()
  return (
    <div
      className={
        buttonForm ? classes.loadingContainerButton : classes.loadingContainer
      }
    >
      <ColorCircularProgress size={size ? size : 64} />
    </div>
  )
}
