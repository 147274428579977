import { useEffect, useRef, useState } from "react";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import useStyles from "./styles";
import { images } from "../../utils";
import lists from "../../utils/lists";
import DinamicForm from "../../components/dinamicForm";
import ErrorMsg from "../../components/error";
import { isEmpty, isEmail } from "../../validators/validator";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../utils/constants";
import MuiPhoneNumber from "material-ui-phone-number";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { es } from "date-fns/locale";
import form from "../../services/form";
import { bornDate } from "../../hooks/useDate";
import Loader from "../../components/loader";
import rols from "../../utils/rols";
import { isFirefox } from "../../hooks/usePhone";

const Form = () => {
  const classes = useStyles();

  const bp = window.outerWidth;

  const captchaBp = () => {
    if (bp < 600) {
      return "compact";
    } else {
      return "normal";
    }
  };

  const userData = JSON.parse(localStorage.getItem("user"));

  const validateUserToken = async () => {
    setSendingData(true);
    try {
      const response = await form.validateUser(userData.token);
      if (response?.data?.session === true) {
        const user = userData;
        if (user?.role.id === rols.user) {
          window.location.href = "/encuesta";
        } else if (user?.role?.id === rols.admin) {
          window.location.href = "/citas/dashboard";
        } else if (user?.role?.id === rols.gestor) {
          window.location.href = "/pqrsf/dashboard";
        } else {
          window.location.href = "/citas/referencia-interconsulta";
        }

        setSendingData(false);
      } else {
        localStorage.removeItem("user");
        setSendingData(false);
      }
    } catch (error) {
      setSendingData(false);
    }
  };

  const [flag, setFlag] = useState(false);
  const [genderList, setGenderList] = useState([]);
  const [sendingData, setSendingData] = useState(false);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [respError, setRespError] = useState(false);
  const [errorMsgFlag, setErrorMsgFlag] = useState(false);

  const [passValidate, setPassValidate] = useState({
    password: undefined,
  });

  const [data, setData] = useState({
    full_name: undefined,
    birthdate: undefined,
    email: undefined,
    document_number: undefined,
    document_type: undefined,
    gender: undefined,
    password: undefined,
  });

  useEffect(() => {
    validateUserToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGender = async () => {
    setGenderList(await form.gender());
  };

  const getDocumentType = async () => {
    setDocumentTypeList(await form.documentType());
  };

  useEffect(() => {
    getGender();
    getDocumentType();
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "phone_number") {
      setData({
        ...data,
        phone_number: e.target.value,
      });
    } else {
      if (e.target.name === "passValidate") {
        setPassValidate({
          password: e.target.value?.trim(),
        });
      } else if (e.target.name === "password") {
        setData({
          ...data,
          [e.target.name]: e.target.value?.trim(),
        });
      } else if (e.target.name === "email") {
        setData({
          ...data,
          [e.target.name]: e.target.value?.toLowerCase(),
        });
      } else {
        setData({
          ...data,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const transitionLogin = async (response) => {
    localStorage.setItem("user", JSON.stringify(response?.data));
    const user = response?.data;
    if (user?.role.id === rols.user) {
      window.location.href = "/encuesta";
    }
    if (user?.role?.id === rols.admin) {
      window.location.href = "/citas/dashboard";
    }
    if (user?.role?.id === rols.gestor) {
      window.location.href = "/pqrsf/dashboard";
    }
    if (user?.role?.id === rols.empresa) {
      window.location.href = "/citas/referencia-interconsulta";
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    // eslint-disable-next-line array-callback-return
    Object.entries(data).map(([key, value]) => {
      if (key !== "phone_number") {
        formData.append(key, value);
      }
    });

    if (data?.phone_number) {
      formData.append("phone_number", data?.phone_number);
    }

    setSendingData(true);
    try {
      const response = await form.newUser(formData);
      if (response?.data === null) {
        response?.status === 403
          ? setErrorMsgFlag(true)
          : setErrorMsgFlag(false);
        setFlag(false);
        setRespError(true);
        setSendingData(false);
      } else {
        transitionLogin(response);
      }
    } catch (error) {
      console.log("error");
      setFlag(false);
    }
  };

  const captcha = useRef(null);
  const handleCaptcha = () => {
    if (captcha.current.getValue()) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  };

  const handleDisabled = () => {
    try {
      const filtereddata = Object.entries(data).filter(
        (value) => value[0] !== "phone_number"
      );
      const stateFilter = Object.values(filtereddata).filter(
        (item) =>
          item[1] === undefined || item[1] === "" || item[1].length === 0
      );
      if (
        stateFilter.length > 0 ||
        isEmail(data?.email) === true ||
        data.phone_number.length < 10 ||
        data.phone_number.length > 13 ||
        flag === false ||
        passValidate.password === undefined ||
        passValidate.password === "" ||
        passValidate.password.length < 8 ||
        data?.password?.length < 8 ||
        data?.password !== passValidate.password
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return true;
    }
  };

  const handleError = () => {
    if (passValidate?.password === undefined) {
      return false;
    } else if (isEmpty(passValidate?.password)) {
      return true;
    } else {
      if (data?.password === passValidate?.password) {
        return false;
      } else {
        return true;
      }
    }
  };

  return sendingData ? (
    <Loader />
  ) : (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <Box className={classes.formContainer}>
          <Box className={classes.form}>
            <Box className={classes.formHeader}>
              <img
                src={images.logoSanDiego}
                className={classes.logo}
                alt="logo"
              />
            </Box>
            <Box className={classes.formBody}>
              {respError && (
                <Box className={classes.infoContainerError}>
                  <Typography variante="caption" className={classes.info}>
                    {errorMsgFlag === false
                      ? lists.newUser.error
                      : lists.newUser.error2}
                  </Typography>
                </Box>
              )}
              <Box className={classes.formBodyHeader}>
                <Typography variant="h5" className={classes.title}>
                  {lists.newUser.title}
                </Typography>
                <Typography variant="body1" className={classes.parraf}>
                  {lists.newUser.subtitle}
                </Typography>
              </Box>
              <Box className={classes.nameLastnameContainer}>
                <DinamicForm
                  data={data?.full_name}
                  type={1}
                  label={"Nombre y Apellido *"}
                  name={"full_name"}
                  handleChange={handleChange}
                  maxLength={60}
                />
              </Box>
              <Box className={classes.boxs}>
                <Box className={classes.birthdateContainer}>
                  <LocalizationProvider
                    locale={es}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      label="Fecha de nacimiento *"
                      value={data?.birthdate ? data.birthdate : null}
                      name="birthdate"
                      allowSameDateSelection
                      maxDate={new Date()}
                      inputFormat="dd-MM-yyyy"
                      onChange={(value) => {
                        setData({
                          ...data,
                          birthdate: value !== null ? bornDate(value) : "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          onInput={(e) => {
                            e.target.value = "";
                          }}
                          fullWidth
                          {...params}
                          error={
                            data?.birthdate === undefined
                              ? false
                              : isEmpty(data?.birthdate) || false
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <ErrorMsg data={data?.birthdate} type={1} />
                </Box>
                <Box className={classes.phoneContainer}>
                  <MuiPhoneNumber
                    defaultCountry="co"
                    label="Número de teléfono"
                    variant="outlined"
                    fullWidth
                    disableDropdown={isFirefox()}
                    autoFormat={false}
                    className={classes.phone}
                    name="phone_number"
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "phone_number",
                          value: e.replace(/ /g, ""),
                        },
                      })
                    }
                    value={data?.phone_number ? data.phone_number : null}
                    error={
                      data?.phone_number
                        ? data.phone_number.length < 10 ||
                          data.phone_number.length > 13
                          ? true
                          : false
                        : false
                    }
                  />
                  <ErrorMsg data={data?.phone_number} type={3} />
                </Box>
              </Box>
              <Box className={classes.boxs2}>
                <Box className={classes.emailContainer}>
                  <span className={classes.auxText}>
                    Registrar una cuenta de correo personal
                  </span>
                  <DinamicForm
                    label={"Correo electrónico *"}
                    type={3}
                    name={"email"}
                    handleChange={handleChange}
                    data={data?.email}
                  />
                </Box>
                <Box className={classes.genderContainer}>
                  <DinamicForm
                    type={5}
                    name={"gender"}
                    data={data?.gender}
                    options={genderList?.value || []}
                    label={"Género *"}
                    handleChange={handleChange}
                  />
                </Box>
              </Box>
              <Box className={classes.boxs}>
                <Box className={classes.documentTypeContainer}>
                  <DinamicForm
                    type={8}
                    disabled={data.user_id ? true : false}
                    name={"document_type"}
                    data={data?.document_type}
                    options={documentTypeList?.value || []}
                    label={"Tipo de documento *"}
                    handleChange={handleChange}
                  />
                </Box>
                <Box className={classes.documentNumContainer}>
                  <DinamicForm
                    disabled={data.user_id ? true : false}
                    label={"Número de documento *"}
                    name={"document_number"}
                    handleChange={handleChange}
                    data={data?.document_number}
                    type={9}
                  />
                </Box>
              </Box>
              <Box className={classes.boxs2}>
                <Box className={classes.passwordContainer}>
                  <span className={classes.auxText}>
                    Ingresa mínimo 8 caracteres
                  </span>
                  <TextField
                    label="Contraseña *"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    autoComplete="current-password"
                    variant="outlined"
                    fullWidth
                    inputProps={{ minLength: 8 }}
                    value={data?.password ? data.password : ""}
                    error={
                      data.password === undefined
                        ? false
                        : isEmpty(data?.password) ||
                        data?.password.length < 8 ||
                        false
                    }
                  />
                  <ErrorMsg data={data?.password} type={5} />
                </Box>
                <Box className={classes.repitPasswordContainer}>
                  <TextField
                    label="Repetir contraseña *"
                    type="password"
                    onChange={handleChange}
                    autoComplete="current-password"
                    variant="outlined"
                    name="passValidate"
                    fullWidth
                    inputProps={{ minLength: 8 }}
                    value={passValidate?.password ? passValidate.password : ""}
                    error={handleError()}
                  />
                  <ErrorMsg data={passValidate?.password} type={1} />
                  <Box className={classes.errorContainer}>
                    {passValidate?.password &&
                      data?.password !== passValidate?.password &&
                      isEmpty(!data) && (
                        <Typography variant="caption" color="error">
                          {lists.errors.password}
                        </Typography>
                      )}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.captchaContainer}>
                <ReCAPTCHA
                  sitekey={recaptchaKey}
                  onChange={handleCaptcha}
                  ref={captcha}
                  size={captchaBp()}
                />
              </Box>
            </Box>

            <Box className={classes.buttonsContainer}>
              <Box className={classes.buttons}>
                <Box className={classes.buttonCotnainer}>
                  {sendingData ? (
                    <Loader size={30.5} buttonForm={true} />
                  ) : (
                    <Button
                      variant="contained"
                      className={classes.button}
                      disabled={handleDisabled()}
                      onClick={handleSubmit}
                    >
                      {lists.newUser.button2}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="horizontal"
              flexItem
              className={classes.divider}
            />
            <Box className={classes.formFooterContainer}>
              <Box className={classes.formFooter}>
                <Box className={classes.newUserTextContainer}>
                  <Typography className={classes.newUserText}>
                    {lists.newUser.msg1}
                  </Typography>
                </Box>
                <Box className={classes.newUserContainer}>
                  <Button
                    variant="contained"
                    className={classes.newUser}
                    component={Link}
                    to="/"
                  >
                    {lists.newUser.button}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <img src={images.img2} alt="logo" className={classes.imgContainer} />
          {/* <Box className={classes.imgContainer}></Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Form;
