import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import Form from "../pages/form";
import Recovery from "../pages/recovery";
import RecoveryPassword from "../pages/recoveryPassword";

const LayoutRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/register" element={<Form />} />
      <Route exact path="/recovery" element={<Recovery />} />
      <Route exact path="/recovery_password" element={<RecoveryPassword />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

export default LayoutRouter;
