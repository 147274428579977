const lists = {
  errors: {
    required: "Campo obligatorio",
    invalidEmail: "Ingresa correo válido",
    invalidPhone: "Ingresa teléfono válido",
    documentType: "Selecciona un tipo de documento",
    documentNum: "Ingresa un número de documento",
    gender: "Selecciona un género",
    state: "Selecciona un departamento",
    counties: "Selecciona un municipio",
    eps: "Selecciona una EPS",
    headquarter: "Selecciona una sede",
    pqrType: "Selecciona un tipo de PQR",
    whoResides: "Selecciona un tipo de persona",
    service: "Selecciona un servicio",
    description: "Ingresa una descripción",
    password: "Las contraseñas no coinciden",
  },

  home: {
    title: "Inicio de Sesión",
    subtitle: "Inicia sesión para hacer uso de nuestros servicios y beneficios",
    button: "Iniciar Sesión",
    button2: "Nuevo Usuario",
    link1: "¿Problemas al iniciar sesión?",
    msg1: "¿Aún no tienes una cuenta de usuario?",
    error: "Las credenciales no son válidas, intente de nuevo.",
  },

  recovery: {
    title: "Recuperar tu cuenta",
    subtitle:
      "Si presentas problemas al iniciar sesión, por favor ingresa tu correo electrónico.",
    subtitle2: "Ingresa una contraseña nueva",
    subtitle3: "Contraseña Actualizada",
    button: "Iniciar Sesión",
    button2: "Recuperar",
    button3: "Continuar",
    button4: "Enviar nuevamente",
    msg1: "¿Ya tienes una cuenta de usuario?",
    msg2: "¿No recibiste el correo electrónico?",
    success: "Se ha enviado un correo electrónico a tu cuenta de correo.",
    error: "El correo electrónico no existe.",
  },

  newUser: {
    title: "Nuevo Usuario",
    subtitle:
      "Por favor completa la información requerida para crear tu cuenta de usuario",
    button: "Iniciar Sesión",
    button2: "Registrarme",
    msg1: "¿Ya tienes una cuenta de usuario?",
    error: "Verificar los datos ingresados o comuniquese con el administrador.",
    error2: "El usuario ya se encuentra registrado.",
  },
}

export default lists
