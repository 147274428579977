import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import useStyles from "./styles";
import { images } from "../../utils";
import lists from "../../utils/lists";
import { useEffect, useRef, useState } from "react";
import ErrorMsg from "../../components/error";
import { isEmpty } from "../../validators/validator";
import { Link, useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../utils/constants";
import form from "../../services/form";
import Loader from "../../components/loader";

const RecoveryPassword = () => {
  const classes = useStyles();
  let location = useLocation();
  let query = new URLSearchParams(location.search);
  let ticketId = query.get("token");

  const bp = window.outerWidth;

  const captchaBp = () => {
    if (bp < 600) {
      return "compact";
    } else {
      return "normal";
    }
  };

  const userData = JSON.parse(localStorage.getItem("user"));

  const validateUserToken = async () => {
    setSendingData(true);
    try {
      const response = await form.validateUser(userData.token);
      if (response?.data?.session === true) {
        window.location.href = "/encuesta";
        setSendingData(false);
      } else {
        localStorage.removeItem("user");
        setSendingData(false);
      }
    } catch (error) {
      setSendingData(false);
    }
  };

  const [data, setData] = useState({
    password: undefined,
    repeat_password: undefined,
    token_verified: ticketId ? ticketId : undefined,
  });
  const [flag, setFlag] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [responseFlag, setResponseFlag] = useState(false);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    validateUserToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDisabled = () => {
    try {
      const stateFilter = Object.values(data).filter(
        (item) => item === undefined || item === "" || item.length === 0
      );
      if (
        stateFilter.length > 0 ||
        flag === false ||
        data?.repeat_password === undefined ||
        data?.repeat_password === "" ||
        data?.repeat_password?.length < 8 ||
        data?.password?.length < 8 ||
        data?.password !== data?.repeat_password
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return true;
    }
  };

  const captcha = useRef(null);
  const handleCaptcha = () => {
    if (captcha.current.getValue()) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  };

  const handleError = () => {
    if (data?.repeat_password === undefined) {
      return false;
    } else if (isEmpty(data?.repeat_password)) {
      return true;
    } else {
      if (data?.password === data?.repeat_password) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    // eslint-disable-next-line array-callback-return
    Object.entries(data).map(([key, value]) => {
      formData.append(key, value);
    });
    setSendingData(true);
    try {
      const response = await form.change_password(formData);

      if (response?.data === null) {
        console.log("error");
        setResponseFlag(false);
        setFlag(false);
      } else {
        setResponseFlag(true);
        setFlag(false);
      }
      setSendingData(false);
    } catch (error) {
      console.log("error");
      setSendingData(false);
      setFlag(false);
    }
  };

  return sendingData ? (
    <Loader />
  ) : (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <Box className={classes.loginContainer}>
          <Box className={classes.login}>
            <Box className={classes.loginHeader}>
              <img
                src={images.logoSanDiego}
                className={classes.logo}
                alt="logo"
              />
            </Box>
            <Box className={classes.loginBody}>
              <Box className={classes.loginBodyHeader}>
                <Typography variant="h5" className={classes.title}>
                  {lists.recovery.title}
                </Typography>
                <Typography variant="body1" className={classes.parraf}>
                  {responseFlag === false
                    ? lists.recovery.subtitle2
                    : lists.recovery.subtitle3}
                </Typography>
              </Box>
              {responseFlag ? (
                <Box className={classes.msg}>
                  <Box className={classes.infoContainer}>
                    <Typography variante="caption" className={classes.info}>
                      <b>Información verificada</b>{" "}
                    </Typography>
                    <Typography variante="caption" className={classes.info}>
                      Se ha cambiado la contraseña, te invitamos a iniciar
                      sesión.
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box className={classes.loginBodyContent}>
                    <Box className={classes.passwordContainer}>
                      <TextField
                        label="Contraseña *"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        autoComplete="current-password"
                        variant="outlined"
                        fullWidth
                        inputProps={{ minLength: 8 }}
                        value={data?.password ? data.password : ""}
                        error={
                          data?.password === undefined
                            ? false
                            : isEmpty(data?.password) ||
                              data?.password.length < 8 ||
                              false
                        }
                      />
                      <ErrorMsg data={data.password} type={5} />
                    </Box>
                    <Box className={classes.passwordContainer}>
                      <TextField
                        label="Repetir contraseña *"
                        type="password"
                        name="repeat_password"
                        onChange={handleChange}
                        autoComplete="current-password"
                        variant="outlined"
                        fullWidth
                        inputProps={{ minLength: 8 }}
                        value={
                          data?.repeat_password ? data.repeat_password : ""
                        }
                        error={handleError()}
                      />
                      <ErrorMsg data={data?.repeat_password} type={1} />
                      <Box className={classes.errorContainer}>
                        {data?.repeat_password &&
                          data?.password !== data?.repeat_password &&
                          isEmpty(!data) && (
                            <Typography variant="caption" color="error">
                              {lists.errors.password}
                            </Typography>
                          )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.captchaContainer}>
                    <ReCAPTCHA
                      sitekey={recaptchaKey}
                      onChange={handleCaptcha}
                      ref={captcha}
                      size={captchaBp()}
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box className={classes.buttonsContainer}>
              <Box className={classes.buttons}>
                <Box className={classes.buttonCotnainer}>
                  {sendingData ? (
                    <Loader size={30.5} buttonForm={true} />
                  ) : responseFlag === false ? (
                    <Button
                      variant="contained"
                      className={classes.button}
                      disabled={handleDisabled()}
                      onClick={handleSubmit}
                    >
                      {lists.recovery.button2}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className={classes.button}
                      component={Link}
                      to="/"
                    >
                      {lists.recovery.button3}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="horizontal"
              flexItem
              className={classes.divider}
            />
            <Box className={classes.loginFooterContainer}>
              <Box className={classes.loginFooter}>
                <Box className={classes.newUserTextContainer}>
                  <Button className={classes.newUserText}>
                    {lists.recovery.msg1}
                  </Button>
                </Box>
                <Box className={classes.newUserContainer}>
                  <Button
                    variant="contained"
                    className={classes.newUser}
                    component={Link}
                    to="/"
                  >
                    {lists.recovery.button}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.imgContainer}></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RecoveryPassword;
