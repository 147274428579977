import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import useStyles from "./styles";
import { images } from "../../utils";
import lists from "../../utils/lists";
import { useEffect, useRef, useState } from "react";
import DinamicForm from "../../components/dinamicForm";
import ErrorMsg from "../../components/error";
import { isEmpty, isEmail } from "../../validators/validator";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../utils/constants";
import Loader from "../../components/loader";
import form from "../../services/form";
import rols from "../../utils/rols";

const Home = () => {
  const classes = useStyles();

  const bp = window.outerWidth;

  const captchaBp = () => {
    if (bp < 600) {
      return "compact";
    } else {
      return "normal";
    }
  };
  const userData = JSON.parse(localStorage.getItem("user"));

  const validateUserToken = async () => {
    setSendingData(true);
    try {
      const response = await form.validateUser(userData.token);
      if (response?.data?.session === true) {
        const user = userData;
        if (user?.role.id === rols.user) {
          window.location.href = "/encuesta";
        } else if (user?.role?.id === rols.admin) {
          window.location.href = "/citas/dashboard";
        } else if (user?.role?.id === rols.gestor) {
          window.location.href = "/pqrsf/dashboard";
        } else {
          window.location.href = "/citas/referencia-interconsulta";
        }
        setSendingData(false);
      } else {
        localStorage.removeItem("user");
        setSendingData(false);
      }
    } catch (error) {
      setSendingData(false);
    }
  };

  const [sendingData, setSendingData] = useState(false);
  const [respError, setRespError] = useState(false);
  const [data, setData] = useState({
    email: undefined,
    password: undefined,
  });
  const [flag, setFlag] = useState(false);

  const captcha = useRef(null);
  const handleCaptcha = () => {
    if (captcha.current.getValue()) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  };

  useEffect(() => {
    validateUserToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleDisabled = () => {
    try {
      const filtereddata = Object.entries(data).filter(
        (value) => value[0] !== "documents"
      );
      const stateFilter = Object.values(filtereddata).filter(
        (item) =>
          item[1] === undefined || item[1] === "" || item[1].length === 0
      );
      if (
        stateFilter.length > 0 ||
        isEmail(data?.email) === true ||
        flag === false ||
        data?.password?.length < 8
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return true;
    }
  };

  const transitionLogin = async (response) => {
    localStorage.setItem("user", JSON.stringify(response?.data));
    // setSendingData(false)
    const user = response?.data;
    if (user?.role?.id === rols.user) {
      window.location.href = "/encuesta";
    }
    if (user?.role?.id === rols.admin) {
      window.location.href = "/citas/dashboard";
    }
    if (user?.role?.id === rols.gestor) {
      window.location.href = "/pqrsf/dashboard";
    }
    if (user?.role?.id === rols.empresa) {
      window.location.href = "/citas/referencia-interconsulta";
    }
    if (user?.role?.id === rols.eps) {
      window.location.href = "/pqrsf/pqrsflist";
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    // eslint-disable-next-line array-callback-return
    Object.entries(data).map(([key, value]) => {
      formData.append(key, value);
    });
    setSendingData(true);
    try {
      const response = await form.sendData(formData);
      if (response?.data === null) {
        setRespError(true);
        setData({
          email: "",
          password: "",
        });
        console.log("error");
        setFlag(false);
      } else {
        transitionLogin(response);
      }
      setSendingData(false);
    } catch (error) {
      console.log("error");
      setFlag(false);
    }
  };

  // console.log(user)

  return sendingData ? (
    <Loader />
  ) : (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <Box className={classes.loginContainer}>
          <Box className={classes.login}>
            <Box className={classes.loginHeader}>
              <img
                src={images.logoSanDiego}
                className={classes.logo}
                alt="logo"
              />
            </Box>
            <Box className={classes.loginBody}>

              <Box className={classes.loginBodyHeader}>
                <Typography variant="h5" className={classes.title}>
                  {lists.home.title}
                </Typography>
                <Typography variant="body1" className={classes.parraf}>
                  {lists.home.subtitle}
                </Typography>
                {respError && (
                  <Box className={classes.infoContainerError}>
                    <Typography variante="caption" className={classes.info}>
                      {lists.home.error}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box className={classes.loginBodyContent}>

                <Box className={classes.emailContainer}>
                  <DinamicForm
                    label={"Correo electrónico *"}
                    type={3}
                    name={"email"}
                    handleChange={handleChange}
                    data={data?.email}
                  />
                </Box>

                <Box className={classes.passwordContainer}>
                  <TextField
                    label="Contraseña *"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    autoComplete="current-password"
                    variant="outlined"
                    fullWidth
                    value={data?.password ? data.password : ""}
                    error={
                      data.password === undefined
                        ? false
                        : isEmpty(data?.password) ||
                        data?.password.length < 8 ||
                        false
                    }
                  />
                  <ErrorMsg data={data.password} type={5} />
                </Box>

              </Box>

              <Box className={classes.captchaContainer}>
                <ReCAPTCHA
                  sitekey={recaptchaKey}
                  onChange={handleCaptcha}
                  ref={captcha}
                  size={captchaBp()}
                />
              </Box>

            </Box>
            <Box className={classes.buttonsContainer}>
              <Box className={classes.buttons}>
                <Box className={classes.buttonCotnainer}>
                  {sendingData ? (
                    <Loader size={30.5} buttonForm={true} />
                  ) : (
                    <Button
                      variant="contained"
                      className={classes.button}
                      disabled={handleDisabled()}
                      component={Link}
                      onClick={handleSubmit}
                      to="/"
                    >
                      {lists.home.button}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="horizontal"
              flexItem
              className={classes.divider}
            />
            <Box className={classes.loginFooterContainer}>
              <Box className={classes.loginFooter}>

                <Box className={classes.newUserTextContainer}>
                  <Button
                    variant="contained"
                    className={classes.newUser}
                    component={Link}
                    to="/register"
                  >
                    {lists.home.msg1}
                  </Button>
                </Box>

                <Box className={classes.newUserContainer}>
                  <Button
                    variant="contained"
                    className={classes.newUser}
                    component={Link}
                    to="/recovery"
                  >
                    {lists.home.link1}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.imgContainer}></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
