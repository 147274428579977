import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    position: "absolute",
    top: "50px",
    left: "5px",
  },

  // errorContainerDescription: {
  //   position: "absolute",
  //   top: "109px",
  //   left: "5px",
  // },

  // errorContainerFiles: {
  //   position: "absolute",
  //   top: "42px",
  //   left: "5px",
  //   display: "flex",
  //   flexDirection: "column",
  // },
}))

export default useStyles
