import { Box, Button, Divider, Typography } from "@mui/material";
import useStyles from "./styles";
import { images } from "../../utils";
import lists from "../../utils/lists";
import { useEffect, useRef, useState } from "react";
import DinamicForm from "../../components/dinamicForm";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../utils/constants";
import Loader from "../../components/loader";
import form from "../../services/form";

const Recovery = () => {
  const classes = useStyles();

  const bp = window.outerWidth;

  const captchaBp = () => {
    if (bp < 600) {
      return "compact";
    } else {
      return "normal";
    }
  };

  const userData = JSON.parse(localStorage.getItem("user"));

  const validateUserToken = async () => {
    setSendingData(true);
    try {
      const response = await form.validateUser(userData.token);
      if (response?.data?.session === true) {
        window.location.href = "/encuesta";
        setFlag(false);
        setSendingData(false);
      } else {
        localStorage.removeItem("user");
        setFlag(false);
        setSendingData(false);
      }
    } catch (error) {
      setFlag(false);
      setSendingData(false);
    }
  };

  const [data, setData] = useState({
    email: undefined,
  });
  const [flag, setFlag] = useState(false);
  const [respError, setRespError] = useState(false);
  const [respSuccess, setRespSuccess] = useState(false);
  const [sendingData, setSendingData] = useState(false);

  const captcha = useRef(null);
  const handleCaptcha = () => {
    if (captcha.current.getValue()) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    validateUserToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDisabled = () => {
    try {
      const stateFilter = Object.values(data).filter(
        (item) => item === undefined || item === "" || item.length === 0
      );
      if (stateFilter.length > 0 || flag === false) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return true;
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    // eslint-disable-next-line array-callback-return
    Object.entries(data).map(([key, value]) => {
      formData.append(key, value);
    });
    setSendingData(true);
    try {
      const response = await form.recovery(formData);

      if (response?.data === null || response?.data?.email_send === false) {
        console.log("error");
        setRespError(true);
        setRespSuccess(false);
        setFlag(false);
      } else {
        setRespSuccess(true);
        setRespError(false);
        setFlag(false);
      }
      setSendingData(false);
    } catch (error) {
      console.log("error");
      setSendingData(false);
      setRespError(true);
      setFlag(false);
    }
  };

  return sendingData ? (
    <Loader />
  ) : (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <Box className={classes.loginContainer}>
          <Box className={classes.login}>
            <Box className={classes.loginHeader}>
              <img
                src={images.logoSanDiego}
                className={classes.logo}
                alt="logo"
              />
            </Box>
            <Box className={classes.loginBody}>
              <Box className={classes.loginBodyHeader}>
                <Typography variant="h5" className={classes.title}>
                  {lists.recovery.title}
                </Typography>
                <Typography variant="body1" className={classes.parraf}>
                  {lists.recovery.subtitle}
                </Typography>
                {respSuccess && (
                  <Box className={classes.infoContainer}>
                    <Typography variante="caption" className={classes.info}>
                      {lists.recovery.success}
                    </Typography>
                  </Box>
                )}
                {respError && (
                  <Box className={classes.infoContainerError}>
                    <Typography variante="caption" className={classes.info}>
                      {lists.recovery.error}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box className={classes.loginBodyContent}>
                <Box className={classes.emailContainer}>
                  <DinamicForm
                    label={"Correo electrónico *"}
                    type={10}
                    name={"email"}
                    handleChange={handleChange}
                    data={data?.email}
                    respError={respError}
                    respSuccess={respSuccess}
                  />
                </Box>
              </Box>
              <Box className={classes.captchaContainer}>
                <ReCAPTCHA
                  sitekey={recaptchaKey}
                  onChange={handleCaptcha}
                  ref={captcha}
                  size={captchaBp()}
                />
              </Box>
            </Box>
            <Box className={classes.buttonsContainer}>
              <Box className={classes.buttons}>
                <Box className={classes.buttonCotnainer}>
                  {sendingData ? (
                    <Loader size={30.5} buttonForm={true} />
                  ) : (
                    <Button
                      variant="contained"
                      className={classes.button}
                      disabled={handleDisabled()}
                      onClick={handleSubmit}
                    >
                      {lists.recovery.button2}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="horizontal"
              flexItem
              className={classes.divider}
            />
            <Box className={classes.loginFooterContainer}>
              <Box className={classes.loginFooter}>
                <Box className={classes.newUserTextContainer}>
                  <Typography className={classes.newUserText}>
                    {lists.recovery.msg1}
                  </Typography>
                </Box>
                <Box className={classes.newUserContainer}>
                  <Button
                    variant="contained"
                    className={classes.newUser}
                    component={Link}
                    to="/"
                  >
                    {lists.recovery.button}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.imgContainer}></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Recovery;
