import { makeStyles } from "@mui/styles";
import { colors, images } from "../../utils";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    background: `linear-gradient( ${colors.deepBlue}, ${colors.lightBlue})`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  body: {
    background: colors.white,
    width: "90%",
    minHeight: "50%",
    backgroundColor: colors.white,
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "28px",
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "60%",
    },
  },
  divider: {
    "&.MuiDivider-root": {
      width: "100%",
      margin: "20px 0 10px",
    },
  },

  errorContainer: {
    position: "absolute",
    top: "50px",
    left: "5px",
  },
  login: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
  },
  loginContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },

  title: {
    "&.MuiTypography-root": {
      fontWeight: "bold",
      fontSize: "1.2rem",
      color: colors.primary,
      textAlign: "left",
      [theme.breakpoints.up("md")]: {
        "&.MuiTypography-root": {
          fontSize: "1.4rem",
        },
      },
    },
  },

  loginHeader: {
    width: "100%",
  },

  logo: {
    width: "30%",
    margin: "7% 0 0 8%",
    [theme.breakpoints.up("lg")]: {
      width: "25%",
      margin: "2% 0 0 6%",
    },
  },

  loginBody: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "3%",
  },

  loginBodyHeader: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
  },

  parraf: {
    "&.MuiTypography-root": {
      marginTop: "5px",
      color: colors.greyStrong,
      textAlign: "left",
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      "&.MuiTypography-root": {
        fontSize: "1.2rem",
      },
    },
  },

  imgContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
      height: "45rem",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      borderRadius: "0 29px 29px 0",
      backgroundPosition: "center",
      backgroundImage: `url(${images.img3})`,
    },
  },

  emailContainer: {
    width: "80%",
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      marginTop: "25px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },

  loginBodyContent: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "3%",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "85%",
    },
  },

  captchaContainer: {
    marginTop: "20px",
    width: "85%",
    minHeight: "78px",
  },

  buttonsContainer: {
    width: "80%",
    marginTop: "20px",
    alignItems: "center",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },

  buttons: {
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      width: "85%",
    },
  },

  button: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      "&.MuiButton-root": {
        fontSize: "1.1rem",
      },
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },

  buttonCotnainer: {
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  loginProblemCotnainer: {
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  loginFooterContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "3% 0",
  },
  loginFooter: {
    width: "85%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  newUser: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "80%",
    },
    "&.MuiButton-root": {
      backgroundColor: colors.green,
      fontSize: "0.8rem",
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.deepGreen,
      },
    },
    [theme.breakpoints.up("md")]: {
      "&.MuiButton-root": {
        fontSize: "1.1rem",
      },
    },
  },
  newUserText: {
    width: "100%",
    color: "#1976d2",
    lineHeight: 1.75,
    textAlign: "center",
    fontSize: "2.8rem",
    fontWeight: "bold",
    [theme.breakpoints.up("lg")]: {
      width: "90%",
    },
    "&.MuiButton-root": {
      textDecoration: "none",
      fontSize: "0.8rem",
      textTransform: "none",
      fontWeight: "bold",
      textAlign: "left",
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1.1rem",
      },
    },
  },

  newUserTextContainer: {
    width: "50%",
  },
  newUserContainer: {
    width: "50%",
    [theme.breakpoints.up("lg")]: {
      textAlign: "right",
    },
  },

  infoContainer: {
    backgroundColor: colors.green,
    borderRadius: "5px",
    padding: "2%",
    marginTop: "1%",
  },

  infoContainerError: {
    backgroundColor: colors.danger,
    borderRadius: "5px",
    padding: "2%",
    marginTop: "1%",
  },

  info: {
    color: colors.white,
  },
}));

export default useStyles;
