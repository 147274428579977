import { apiUrl } from "./constants"

async function GET(url) {
  try {
    const response = await fetch(apiUrl + url, {
      method: "GET",
    })
    const json = await response.json()
    if (response.status <= 299) return { data: json, error: null }
    return { data: null, error: json, status: response.status }
  } catch (error) {
    return { data: null, error: error }
  }
}

async function POST(url, body, token) {
  let properties = {
    method: "POST",
    headers: { Accept: "*/*" },
    body: body,
  }
  if (token)
    properties.headers = {
      Authorization: `Bearer ${token}`,
    }
  try {
    const response = await fetch(apiUrl + url, properties)
    const json = await response.json()
    if (response.status <= 299) return { data: json, error: null }
    return { data: null, error: json, status: response.status }
  } catch (error) {
    return { data: null, error: error }
  }
}

export { GET, POST }
