import { isEmpty, isEmail } from "../../validators/validator"
import { Autocomplete, TextField } from "@mui/material"
import ErrorMsg from "../error"
import useStyles from "./styles"

const DinamicForm = ({
  data,
  type,
  label,
  name,
  handleChange,
  disabled,
  options,
  stateList,
  state_id,
  countiesList,
  respSuccess,
  respError,
  maxLength,
}) => {
  const classes = useStyles()
  return type === 1 ? (
    <>
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        inputProps={{ maxLength: maxLength }}
        name={name}
        onInput={(e) => {
          e.target.value = e.target.value.replace(/[^A-Za-zñÑ\s]+$/g, "")
        }}
        onChange={handleChange}
        value={data ? data : ""}
        error={data === undefined ? false : isEmpty(data) || false}
      />
      <ErrorMsg data={data} type={1} />
    </>
  ) : type === 2 ? (
    <>
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        onInput={(e) => {
          e.target.value = e.target.value
            .replace(/[^0-9]/, "")
            .replace(/(\..*)\./g, "$1")
        }}
        name={name}
        onChange={handleChange}
        value={data ? data : ""}
        error={data === undefined ? false : isEmpty(data) || false}
      />
      <ErrorMsg data={data} type={1} />
    </>
  ) : type === 3 ? (
    <>
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        disabled={disabled}
        name={name}
        onChange={handleChange}
        value={data ? data : ""}
        error={data === undefined ? false : isEmail(data) || false}
      />
      <ErrorMsg data={data} type={2} />
    </>
  ) : type === 4 ? (
    <>
      <TextField
        label={label}
        variant="outlined"
        multiline
        rows={4.5}
        fullWidth
        name={name}
        onChange={handleChange}
        value={data ? data : ""}
        error={data === undefined ? false : isEmpty(data) || false}
      />
      <ErrorMsg data={data} type={4} />
    </>
  ) : type === 5 ? (
    <>
      <Autocomplete
        disablePortal
        name={name}
        onChange={(_, value) => {
          handleChange({ target: { name: `${name}`, value } })
        }}
        value={data ? data : null}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={data === undefined ? false : isEmpty(data) || false}
          />
        )}
      />
      <ErrorMsg data={data} type={1} />
    </>
  ) : type === 6 ? (
    <>
      <Autocomplete
        disablePortal
        name={name}
        onChange={(_, value) => {
          handleChange({ target: { name: `${name}`, value } })
        }}
        value={data ? stateList.find((state) => state.id === data) : null}
        options={options}
        getOptionLabel={(option) => option?.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={data === undefined ? false : isEmpty(data) || false}
          />
        )}
      />
      <ErrorMsg data={data} type={1} />
    </>
  ) : type === 7 ? (
    <>
      <Autocomplete
        disablePortal
        disabled={disabled}
        name={name}
        onChange={(_, value) => {
          handleChange({ target: { name: `${name}`, value } })
        }}
        value={
          data && state_id
            ? countiesList.find((county) => county.id === data)
            : null
        }
        options={countiesList || []}
        getOptionLabel={(option) => option?.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Municipio *"
            error={data === undefined ? false : isEmpty(data) || false}
          />
        )}
      />
      <ErrorMsg data={data} type={1} />
    </>
  ) : type === 8 ? (
    <>
      <Autocomplete
        disablePortal
        disabled={disabled}
        name={name}
        onChange={(_, value) => {
          handleChange({ target: { name: `${name}`, value } })
        }}
        value={data ? data : null}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={data === undefined ? false : isEmpty(data) || false}
          />
        )}
      />
      <ErrorMsg data={data} type={1} />
    </>
  ) : type === 9 ? (
    <>
      <TextField
        label={label}
        disabled={disabled}
        variant="outlined"
        fullWidth
        onInput={(e) => {
          e.target.value = e.target.value
            .replace(/[^0-9]/, "")
            .replace(/(\..*)\./g, "$1")
            .replace(/[^0-9]/g, "")
        }}
        name={name}
        onChange={handleChange}
        value={data ? data : ""}
        error={data === undefined ? false : isEmpty(data) || false}
      />
      <ErrorMsg data={data} type={1} />
    </>
  ) : (
    <>
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        disabled={disabled}
        name={name}
        onChange={handleChange}
        value={data ? data : ""}
        error={data === undefined ? false : isEmail(data) || false || respError}
        className={respSuccess ? classes.success : ""}
      />
      <ErrorMsg data={data} type={2} />
    </>
  )
}

export default DinamicForm
